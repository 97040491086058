import Idle from 'idle-js'

const idle = new Idle({
  idle: 10000,
  events: ['mousemove', 'keydown', 'mousedown', 'touchstart'],
  onIdle: () => {
    const el = document.getElementById('idle-popup')
    el.style = 'display: block'
    idle.stop()
  },
})

document.addEventListener('DOMContentLoaded', () => {
  const popup = document.getElementById('idle-popup')
  const closeButton = popup.querySelector('.idle__close')

  closeButton.addEventListener('click', () => {
    popup.style = 'display: none'
  })

  idle.start()
})
